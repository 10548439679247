.sol-home-sect-card {
	padding: 65px 75px;
	background-color: var(--sol-bg-card);
	border-radius: 12px;
	@media (max-width: 1200px) {
		padding: 50px;
	}
	@media (max-width: 992px) {
		padding: 40px;
	}
	@media (max-width: 576px) {
		padding: 20px;
	}
}
