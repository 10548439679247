@import 'src/styles/variables.scss';

.sol-staking-your-information {
	padding: 30px;
	border-radius: 24px;
	background: var(--sol-app-card-bg);
	backdrop-filter: blur(5px);
	&-block {
		padding-bottom: 15px;
		display: flex;
		flex-direction: column;
		row-gap: 8px;
		b {
			color: var(--sol-color-heading);
			font-size: 18px;
			font-weight: 900;
		}
		&.last {
			padding-bottom: 20px;
			padding-top: 15px;
			border-top: 1px solid $border_color;
		}
	}
	&-action {
		display: flex;
		column-gap: 15px;
		.sol-btn {
			flex: 1;
			@media(max-width:1399px){
				min-width: 80px;
			}
		}
	}
}
