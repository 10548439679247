.sol-home-card {
	padding: 25px 25px 16px;
	background-color: var(--sol-bg-card);
	border: 1px solid var(--sol-border-color);
	border-radius: 10px;
	transition: all ease-in-out 0.3s;
	@media (max-width: 992px) {
		padding: 20px 20px 16px;
	}
	&:hover {
		box-shadow: 1px 0px 30px rgba($color: #1c46ff, $alpha: 0.2);
	}
}
