.sol-home-banner {
	overflow: hidden;
	padding: 80px 0;
	@media (max-width: 576px) {
		padding: 50px 0;
	}
	&-image {
		width: 100%;
	}
	&-sub-title {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 36px;
		border: 1px solid var(--sol-color-highlight);
		border-radius: 18px;
		margin-bottom: 14px;
		color: var(--sol-color-highlight);
	}
	&-title {
		font-size: 48px;
		font-weight: 600;
		line-height: 1.1;
		color: var(--sol-color-heading);
		margin-bottom: 27px;
		@media (max-width: 1200px) {
			font-size: 36px;
		}
		@media (max-width: 576px) {
			font-size: 24px;
			line-height: 1.3;
		}
		strong {
			display: block;
			font-weight: 600;
			color: var(--sol-color-highlight);
		}
	}
	&-description {
		width: 453px;
		max-width: 100%;
		margin-bottom: 24px;
		@media (max-width: 992px) {
			width: 100%;
			margin-bottom: 40px;
		}
		a {
			display: block;
			color: var(--sol-color);
			@media (max-width: 576px) {
				word-break: break-all;
			}
			&:hover {
				color: var(--sol-color-highlight);
			}
		}
	}
	&-actions {
		display: flex;
		align-items: center;
		column-gap: 16px;
		.btn {
			min-width: 100px;
			@media (max-width: 576px) {
				min-width: 80px;
			}
		}
	}
}
