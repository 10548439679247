$primary_color: #8350e6;
$secondary_color: #323458;
$white_color: #fff;
$success_color: #2ec145;
$warning_color: #f0b90b;
$danger_color: #e65062;
$text_color: rgba(#fff, 0.6);
$bg_color: #1b1c31;
$bg_secondary_color: #26273b;
$bg_modal: #202136;
$bg_modal_backdrop: #100e29;
$bg_card_color: rgba(#fff, 0.05);
$bg_sub_card_color: rgba(#fff, 0.03);
$sidebar_width: 240px;
$sidebar_collapsed_width: 115px;
$gradient_color: linear-gradient(180deg, #e250e5 6%, #4b50e6 92%);
$card_bg_color: rgba(255, 255, 255, 0.05);
$box_shadow_color: rgba(189, 0, 255, 0.1);
$border_color: rgba(255, 255, 255, 0.1);
$bg_tag_color: #1B1C3199;
