.sol-home-roadmap-item {
	height: 100%;
	&-title {
		font-size: 24px;
		line-height: 28px;
		font-weight: 600;
		color: var(--sol-color-heading);
		margin-bottom: 9px;
		@media (max-width: 992px) {
			font-size: 20px;
			line-height: 1.5;
		}
	}
	&-description {
		margin-bottom: 65px;
		@media (max-width: 992px) {
			margin-bottom: 30px;
		}
	}
	&-details {
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		li {
			padding-left: 35px;
			position: relative;
			@media (max-width: 992px) {
				padding-left: 20px;
			}
			&::before {
				content: '';
				position: absolute;
				top: 5px;
				left: 0;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background-color: var(--sol-color-primary);
				@media (max-width: 992px) {
					top: 6px;
					width: 8px;
					height: 8px;
				}
			}
			&:not(:last-child) {
				margin-bottom: 20px;
				@media (max-width: 576px) {
					margin-bottom: 10px;
				}
			}
		}
	}
}
