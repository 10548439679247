@import 'src/styles/variables.scss';

.sol-pool-card {
	padding: 24px;
	border-radius: 30px;
	border: 1px solid transparent;
	background: var(--sol-app-card-bg);
	backdrop-filter: blur(5px);
	@media (max-width: 767px) {
		padding: 20px;
		border-radius: 20px;
	}
	a {
		&:hover {
			color: var(--sol-color-primary);
			img {
				transform: scale(1.1);
			}
		}
	}
	&-img {
		display: block;
		text-decoration: none;
		margin-bottom: 20px;
		img {
			transform-origin: center;
			transition: all ease-in-out 0.3s;
		}
	}
	&-title {
		color: var(--sol-color-heading);
		font-size: 20px;
		font-weight: 900;
		text-decoration: none;
		display: flex;
		transition: all ease-in-out 0.3s;
	}

	&-status {
		padding: 8px 16px;
		border-radius: 8px;
		text-transform: capitalize;
		color: var(--sol-color-heading);
		font-weight: 700;

		&-opening {
			background: var(--sol-color-primary);
		}

		&-upcoming {
			background: $warning_color;
		}

		&-closed {
			background: $danger_color;
		}
	}

	&-description {
		font-size: 16px;
		padding: 15px 0px;
	}
	&-availabe {
		padding-bottom: 15px;
		.sol-available-on {
			width: 100%;
			span {
				margin-right: auto;
			}
		}
	}
	&-stats {
		margin: 10px 0 20px !important;
		border-top: 1px dashed $border_color;
	}
	&-progress {
		padding: 10px 0;
		&-label {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;
			font-size: 14px;
			.progress-label {
				b {
					color: var(--sol-color-heading);
				}
			}
			.progress-participants {
				b {
					color: var(--sol-color-heading);
				}
			}
		}
		&-info {
			margin-top: 8px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--sol-color-heading);
			font-size: 14px;
			.progress-info-parti {
				color: var(--sol-color);
			}
		}
	}

	&-action {
		padding-top: 20px;
		border-top: 1px dashed $border_color;
		align-items: center;
		.sol-btn {
			width: 100%;
		}
	}
}
