@import "src/styles/variables.scss";

.sol-bridge-form{
    padding: 40px;
    border-radius: 24px;
    background-color: $bg_card_color;
    min-height: 100%;
	@media (max-width: 1399px) {
        padding: 24px;
    }
    @media(max-width:767px){
        padding: 20px;
        border-radius: 20px;
    }
    &-body{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding-bottom: 20px;
    }
    &-action{
        padding-top: 24px;
        border-top: 1px solid rgba($white_color, 0.10);
    }
    &-row{
        display: flex;
        column-gap: 10px;
        @media(max-width:767px){
            flex-direction: column;
            row-gap: 20px;
        }
        .sol-form-group{
            flex: 1;
        }
    }
    .sol-btn-swap{
        padding: 40px 5px 10px;
        background-color: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        @media(max-width:767px){
            display: none;
        }
    }
}
