@import url('aos/dist/aos.css');
.sol-public-template {
    position: relative;
	overflow: hidden;
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}
	@media (min-width: 1400px) {
		.container,
		.container-lg,
		.container-md,
		.container-sm,
		.container-xl,
		.container-xxl {
			max-width: 1260px;
		}
	}
	a {
		transition: all ease-in-out 0.3s;
		text-decoration: none;
	}
	.sol-public-main{
		padding-top: 104px;
		@media (max-width: 576px) {
			padding-top: 84px;
		}
	}
	.sol-public-decor{
        position: absolute;
		z-index: -1;
		&.top-left {
			top: 26px;
			left: -683px;
			animation: zoom-in 20s infinite;
			@media (max-width: 576px) {
				left: -500px;
				top: -500px;
				opacity: 0.3;
				position: fixed;
			}
		}
		&.top-right {
			top: 26px;
			right: -662px;
			animation: zoom-out 20s infinite;
			@media (max-width: 576px) {
				display: none;
			}
		}
		&.bottom-left {
			bottom: 69px;
			left: -683px;
			animation: zoom-out 20s infinite;
			@media (max-width: 576px) {
				display: none;
			}
		}
		&.bottom-right {
			bottom: 69px;
			right: -662px;
			animation: zoom-in 20s infinite;
			@media (max-width: 576px) {
				display: none;
			}
		}
    }
}

@keyframes zoom-in {
	0%,
	100% {
		transform: scale(1.5);
	}
	50% {
		transform: scale(1);
	}
}
@keyframes zoom-out {
	0%,
	100% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(1.5);
		opacity: 0.5;
	}
}
