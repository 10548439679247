.sol-stepper-item {
	display: flex;
	align-items: center;
	column-gap: 15px;
	color: var(--sol-color-heading);
	font-weight: 700;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	opacity: 0.5;
	@media (max-width: 767px) {
		column-gap: 8px;
	}
	&-icon {
		width: 32px;
		height: 32px;
		min-width: 32px;
		background-color: var(--sol-bg);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
	}
	&-text {
		@media (max-width: 767px) {
			font-size: 12px;
		}
	}
	&.completed,
	&.active {
		.sol-stepper-item-icon {
			background-color: var(--sol-color-heading);
			color: var(--sol-bg);
		}
	}
	&.active {
		color: var(--sol-color-heading);
		opacity: 1;
		.sol-stepper-item-icon {
			background-color: var(--sol-color-heading);
			color: var(--sol-bg);
		}
	}
}
