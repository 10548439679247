.sol-home-roadmap {
	padding-top: 100px;
	@media (max-width: 576px) {
		padding-top: 60px;
	}
	.sol-home-sect-title {
		margin-bottom: 70px;
		text-align: center;
		@media (max-width: 576px) {
			margin-bottom: 30px;
		}
	}
	.row {
		--bs-gutter-x: 44px;
		--bs-gutter-y: 44px;
		@media (max-width: 992px) {
			--bs-gutter-x: 20px;
			--bs-gutter-y: 20px;
		}
	}
	&-pagination {
		margin-top: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 20px;
		span {
			cursor: pointer;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: var(--sol-border-color);
			transition: all ease-in-out 0.3s;
			&:hover,
			&.active {
				background-color: var(--sol-color-primary);
			}
		}
	}
}