@import 'src/styles/variables.scss';

.sol-launchpad-opening {
	padding: 40px 50px 40px 40px;
	border-radius: 30px;
	border: 1px solid var(--sol-app-card-border);
	background: var(--sol-app-card-bg);
	box-shadow: 0px 0px 50px 10px $box_shadow_color;
	backdrop-filter: blur(5px);
	@media (max-width: 1399px) {
		padding: 30px;
		border-radius: 24px;
	}
	@media (max-width: 767px) {
		padding: 20px;
		border-radius: 20px;
	}
	&-img {
		display: flex;
		text-decoration: none;
		&:hover {
			img {
				transform: scale(1.05);
			}
		}
		img {
			transform-origin: center;
			transition: all ease-in-out 0.3s;
		}
	}
	&-title {
		color: var(--sol-color-heading);
		font-size: 32px;
		font-weight: 900;
		text-decoration: none;
		display: flex;
		transition: all ease-in-out 0.3s;
		@media (max-width: 1399px) {
			font-size: 28px;
		}
		@media (max-width: 991px) {
			font-size: 24px;
		}
		@media (max-width: 767px) {
			font-size: 20px;
		}
		&:hover {
			color: var(--sol-color-primary);
		}
	}
	&-description {
		font-size: 18px;
		padding: 16px 0px;
		@media (max-width: 767px) {
			font-size: 16px;
		}
	}
	&-stats {
		display: flex;
		align-items: center;
		gap: 40px;
		align-self: stretch;
		padding-top: 10px;
		border-top: 1px solid $border_color;
		@media (max-width: 991px) {
			flex-wrap: wrap;
			row-gap: 24px;
		}
	}
	&-progress {
		padding: 16px 0;
		&-label {
			font-size: 18px;
			margin-bottom: 8px;
			@media (max-width: 991px) {
				font-size: 16px;
			}
		}
		&-info {
			margin-top: 8px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--sol-color-heading);
			@media (max-width: 767px) {
				font-size: 14px;
			}
		}
	}
	&-action {
		padding-top: 24px;
		border-top: 1px solid $border_color;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 767px) {
			flex-direction: column;
			row-gap: 15px;
		}
		.sol-btn {
			min-width: 200px;
			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}
}
