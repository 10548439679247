@import 'src/styles/variables.scss';

.sol-staking-template {
	.row {
		--bs-gutter-x: 32px;
	}
	.sol-staking-panel {
		border-radius: 24px;
		background-color: $bg_card_color;
		backdrop-filter: blur(5px);
	}
}
