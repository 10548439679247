.sol-bridge-select-asset-dialog {
	.sol-search {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px var(--sol-app-card-bg) solid;
		position: relative;
		input {
			display: flex;
			padding: 10px 15px;
			width: 100%;
			height: 56px;
			border-radius: 10px;
			background-color: var(--sol-app-card-bg);
			padding-left: 66px;
			border: 1px transparent solid;
			transition: all ease-in-out 0.3s;
			color: var(--sol-color-heading);
			&:focus {
				outline: 0;
				border-color: var(--sol-app-input-border);
			}
		}
		img {
			position: absolute;
			left: 21px;
			width: 24px;
			top: 16px;
		}
	}
	.sol-assets {
		display: flex;
		flex-direction: column;
		row-gap: 12px;
		&-empty {
			text-align: center;
		}
	}
}
