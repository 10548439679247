.sol-staking-step {
	&-title {
		color: var(--sol-color-heading);
		font-size: 20px;
		font-weight: 900;
		margin-bottom: 15px;
	}
	&-description {
		color: var(--sol-color);
		margin-bottom: 20px;
		b {
			color: var(--sol-color-heading);
			font-weight: 500;
		}
		small {
			i {
				font-size: 14px;
			}
		}
	}
	&-confirm {
		padding: 20px 0;
		display: flex;
		column-gap: 8px;
		a {
			color: var(--sol-color-heading);
			transition: all ease-in-out 0.3s;
			&:hover {
				color: var(--sol-color-primary);
			}
		}
	}
	&-highlight {
		padding-top: 10px;
		color: var(--sol-color-heading);
	}
}
