@import 'src/styles/variables.scss';

.sol-header {
	position: fixed;
	top: 0;
	left: $sidebar_width;
	width: calc(100% - $sidebar_width);
	backdrop-filter: blur(10px);
	z-index: 996;
	padding: 27px 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 90px;
	@media (max-width: 1399px) {
		left: 220px;
		width: calc(100% - 220px);
		padding: 20px 40px;
	}
	@media (max-width: 767px) {
		left: 0;
		width: 100%;
		padding: 20px;
	}
	.sol-breadcrumb {
		@media (max-width: 767px) {
			display: none;
		}
	}
	.sol-logo-sm {
		display: none;
		margin-right: 20px;
		img{
			height: 40px;
		}
		@media (max-width: 767px) {
			display: inline-flex;
		}
	}
	.sol-btn-toggle {
		display: none;
		align-items: center;
		justify-content: center;
		min-width: 40px;
		width: 40px;
		height: 40px;
		background-color: $bg_card_color;
		border-radius: 10px;
		border: 0;
		outline: 0;
		padding: 0;
		@media (max-width: 767px) {
			display: inline-flex;
		}
	}
	&-right {
		display: inline-flex;
		align-items: center;
		column-gap: 10px;
		@media (max-width: 1199px) {
			column-gap: 15px;
		}
		.sol-btn {
			@media (max-width: 767px) {
				padding: 10px 16px;
				height: 40px;
				border-radius: 20px;
				&.sol-btn-your-wallet{
					.sol-icon {
						display: none;
					}
				}
			}
		}
		.sol-btn-disconnect {
			.sol-icon {
				display: none;
			}
			@media (max-width: 767px) {
				width: 36px;
				min-width: 36px;
				padding: 0;
				border: 0;
				span {
					display: none;
					&.sol-icon {
						display: inline-flex;
					}
				}
			}
		}
	}
}
