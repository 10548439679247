.sol-home-tokenomics-item {
	padding: 30px 0 44px;
	text-align: center;
	font-size: 28px;
	line-height: 32px;
	font-weight: 600;
	@media (max-width: 992px) {
		font-size: 20px;
		line-height: 1.5;
	}
	@media (max-width: 576px) {
		line-height: 1.3;
        padding: 20px 10px;
	}
	&-value {
		color: var(--sol-color-highlight);
		margin-bottom: 14px;
        @media (max-width: 576px) {
            font-size: 24px;
            margin-bottom: 5px;
        }
	}
	&-label {
		color: var(--sol-color-heading);
        @media (max-width: 576px) {
            font-size: 16px;
        }
	}
}
