.sol-page-title {
	color: var(--sol-color-heading);
	font-size: 40px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 30px;
	@media(max-width:1199px){
		font-size: 30px;
		margin-bottom: 20px;
	}
    @media(max-width:767px){
		font-size: 26px;
	}
}
