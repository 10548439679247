.sol-home-partners {
	padding-top: 100px;
	@media (max-width: 576px) {
		padding-top: 60px;
	}
	.sol-home-sect-title {
		margin-bottom: 22px;
		text-align: center;
	}
	&-items {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 25px;
		@media (max-width: 992px) {
			row-gap: 0;
		}
		img {
			height: 104px;
			opacity: 0.8;
			transition: all ease-in-out 0.3s;
			@media (max-width: 992px) {
				height: 90px;
			}
			@media (max-width: 576px) {
				height: 70px;
			}
			&:hover {
				opacity: 1;
			}
		}
	}
}
