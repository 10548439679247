.sol-home-tokenomics {
	padding-top: 100px;
	@media (max-width: 576px) {
		padding-top: 60px;
	}
	.sol-home-sect-title {
		margin-bottom: 70px;
		text-align: center;
		@media (max-width: 576px) {
			margin-bottom: 30px;
		}
	}
	.row {
		--bs-gutter-x: 44px;
		--bs-gutter-y: 44px;
		@media (max-width: 992px) {
			--bs-gutter-x: 20px;
			--bs-gutter-y: 20px;
		}
	}
}
