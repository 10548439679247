.sol-public-footer-widget{
    &-title {
    	font-size: 20px;
    	line-height: 1.5;
    	font-weight: 600;
    	color: var(--sol-color-heading);
    	margin-bottom: 39px;
    	@media (max-width: 992px) {
    		font-size: 16px;
    		line-height: 1.5;
    		white-space: nowrap;
    		margin-bottom: 16px;
    		margin-top: 30px;
    	}
		@media (max-width: 576px) {
			margin-top: 15px;
		}
    }
    &-body {
    	ul {
    		list-style: none;
    		margin-bottom: 0;
    		padding: 0;
    		li {
    			a {
    				line-height: 30px;
    				color: var(--sol-color);
    				&:hover {
    					color: var(--sol-color-primary);
    				}
    			}
    		}
    	}
    }
}
