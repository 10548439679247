.sol-staking-stake-step2 {
	.sol-staking-stake-input {
		width: 300px;
		max-width: 100%;
		height: 56px;
		padding: 10px 15px;
		border-radius: 10px;
		background: var(--sol-app-card-bg);
		border: 1px transparent solid;
		transition: all ease-in-out 0.3s;
		outline: 0;
		color: var(--sol-color-heading);
		font-size: 20px;
		font-weight: 500;
		&:focus {
			outline: 0;
			border-color: var(--sol-app-input-border);
		}
	}
	.sol-staking-stake-balance {
		padding: 20px 0;
		b {
			font-weight: 700;
			color: var(--sol-color-heading);
		}
	}
}
