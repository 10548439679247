.sol-overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 9999;
	justify-content: center;
	align-items: center;

	&.show {
		display: flex;
        align-items: center;
        justify-content: center;
	}

    &-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 12px;
        color: #fff;
    }
}
