@import 'src/styles/variables.scss';

.sol-breadcrumb {
	display: flex;
	align-items: center;
	gap: 16px;
	font-size: 18px;
	font-weight: 600;
	&-home {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 46px;
		height: 46px;
		padding: 12px;
		border-radius: 10px;
		background-color: #191b43;
		text-decoration: none;
		img,
		svg {
			height: 22px;
			width: auto;
		}
	}
	&-item {
		display: inline-flex;
		align-items: center;
		column-gap: 16px;
		&-link {
            text-decoration: none;
			color: var(--sol-color);
			transition: all ease-in-out 0.3s;
			&:hover {
				color: var(--sol-color-heading);
			}
		}
		&-active {
			color: var(--sol-color-heading);
		}
	}
	&-decoration {
		font-weight: 400;
	}
}
