.sol-checkpoint {
	display: flex;
	column-gap: 15px;
	&-icon {
		width: 28px;
		min-width: 28px;
	}
	&-info {
		font-size: 14px;
		h5 {
			color: var(--sol-color-heading);
			font-weight: 500;
			@media (max-width: 1399px) {
				font-size: 18px;
			}
		}
	}
}
