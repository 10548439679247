.sol-public-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	padding: 26px 0;
	z-index: 999;
	transition: all ease-in-out 0.3s;
	&.sol-public-fixed {
		background-color: rgba($color: #000, $alpha: 0.2);
		backdrop-filter: blur(6px);
		padding: 16px 0;
	}
	&-inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
