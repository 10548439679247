@import 'src/styles/variables.scss';

.sol-bridge-process-dialog {
	.sol-bridge-process-content {
		padding: 0 0px 0px 30px;
		border-left: 1px solid rgba(255, 255, 255, 0.1);
		@media (max-width: 991px) {
			padding: 0;
			border: 0;
		}
		.sol-bridge-process-body {
			min-height: 310px;
		}
		.sol-bridge-process-title {
			color: var(--sol-color-heading);
			font-size: 20px;
			font-weight: 900;
			margin-bottom: 15px;
		}
		.sol-bridge-process-description {
			margin-bottom: 20px;
			small {
				i {
					font-size: 14px;
				}
			}
			b {
				color: var(--sol-color-heading);
				font-weight: 500;
			}
		}
		.sol-bridge-process-note {
			border-radius: 20px;
			background: var(--sol-app-card-bg);
			padding: 20px;
			display: flex;
			align-items: flex-start;
			column-gap: 10px;
			color: $warning_color;
			&.success {
				color: var(--sol-color-primary);
			}
			&.error {
				color: $danger_color;
			}
			b {
				color: var(--sol-color-heading);
				font-weight: 500;
				padding: 0 3px;
			}
		}
		.sol-bridge-process-action {
			border-top: 1px solid $border_color;
			padding-top: 20px;
			margin-top: 10px;
			display: flex;
			column-gap: 15px;
			.sol-btn {
				min-width: 140px;
			}
		}
	}
}
