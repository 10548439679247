.sol-home-vision {
	padding-top: 100px;
	overflow: hidden;
	@media (max-width: 768px) {
		text-align: center;
	}
	@media (max-width: 576px) {
		padding-top: 60px;
	}
	.sol-home-sect-title {
		margin-bottom: 43px;
		@media (max-width: 576px) {
			margin-bottom: 20px;
		}
	}
	.sol-home-sect-description {
		margin-bottom: 60px;
		width: 463px;
		max-width: 100%;
		@media (max-width: 992px) {
			width: 100%;
			margin-bottom: 22px;
		}
	}
	.sol-home-sect-card-image {
		@media (max-width: 992px) {
			text-align: center;
		}
	}
}
