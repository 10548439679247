@import 'src/styles/variables.scss';

.sol-dashboard-banner {
	padding: 15px 0;
	@media (max-width: 1199px) {
		padding: 30px 0;
	}
	@media (max-width: 991px) {
		margin-bottom: 20px;
	}
	&-description {
		font-size: 16px;
		margin-bottom: 46px;
		@media (max-width: 991px) {
			margin-bottom: 20px;
		}
	}
	&-action {
		display: flex;
		align-items: center;
		gap: 20px;
	}
}
