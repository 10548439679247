.sol-home-features-item {
    display: flex;
    column-gap: 24px;
    margin-bottom: 34px;
    @media (max-width: 1200px) {
        flex-direction: column;
        row-gap: 22px;
    }
    @media (max-width: 576px) {
        flex-direction: row;
        column-gap: 20px;
    }
    &-no {
        min-width: 52px;
        width: 52px;
        height: 52px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: var(--sol-bg-card);
        border: 1px solid var(--sol-border-color);
        border-radius: 11px;
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        color: var(--sol-color-highlight);
    }
    &-title {
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        color: var(--sol-color-heading);
        margin-bottom: 21px;
        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 1.5;
        }
        @media (max-width: 576px) {
            margin-bottom: 10px;
        }
    }
    &-description {
        width: 260px;
        max-width: 100%;
        min-height: 210px;
        @media (max-width: 576px) {
            width: 100%;
            min-height: unset;
        }
    }
}
