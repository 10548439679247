.sol-public-footer-info {
    margin-bottom: 25px;
    @media (max-width: 576px) {
    	display: flex;
        align-items: flex-start;
        column-gap: 20px;
        border-top: 1px rgba($color: #fff, $alpha: .1) dashed;
        padding-bottom: 10px;
        padding-top: 20px;
        margin-bottom: 0;
    }
    img{
        height: 52px;
        margin-bottom: 25px;
        @media (max-width: 576px) {
            margin-bottom: 0;
        }
    }
    p{
        width: 328px;
        max-width: 100%;
        margin-bottom: 0;
        @media (max-width: 576px) {
            width: 100%;
        }
    }
}
