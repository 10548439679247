.sol-home-sect {
	&-sub-title {
		text-align: center;
		color: var(--sol-color-highlight);
		margin-bottom: 20px;
		@media (max-width: 576px) {
			font-size: 16px;
			margin-bottom: 0;
		}
	}
	&-title {
		font-size: 48px;
		font-weight: 600;
		line-height: 55px;
		color: var(--sol-color-heading);
		opacity: 1;
		@media (max-width: 576px) {
			font-size: 24px;
			line-height: 1.3;
		}
	}
	&-description {
		margin-bottom: 60px;
	}
	&-link {
		a {
			color: var(--sol-color-highlight);
			&:hover {
				color: var(--sol-color-primary);
			}
		}
	}
}
