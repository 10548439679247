.sol-form-group {
    width: 100%;
	.sol-form-label {
		color: var(--sol-color-heading);
		margin-bottom: 8px;
	}
	.sol-form-control {
		position: relative;
		&-icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
			img,
			svg {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 5px;
			}
			&.left {
				left: 15px;
				width: 36px;
				height: 36px;
                color: var(--sol-color-heading);
				&.default{
					background-color: var(--sol-color-primary);
					border-radius: 5px;
				}
			}
			&.right {
				right: 15px;
				width: 24px;
				height: 24px;
                color: var(--sol-color);
			}
		}
		&-input {
            width: 100%;
			padding: 10px 15px;
			height: 56px;
			border-radius: 10px;
			background: var(--sol-app-card-bg);
			border: 1px transparent solid;
			transition: all ease-in-out 0.3s;
			color: var(--sol-color-heading);
			font-weight: 500;
			&:disabled {
				opacity: 0.5;
			}
			&:not(:disabled) {
				&:focus {
					outline: 0;
					border-color: var(--sol-app-input-border);
				}
			}
            &:read-only{
                cursor: pointer;
            }
		}
		&.with-left-icon {
			.sol-form-control-input {
				padding-left: 66px;
			}
		}
		&.with-right-icon {
			.sol-form-control-input {
				padding-right: 54px;
			}
		}
	}
	&-note {
		margin-top: 8px;
		display: flex;
		align-items: center;
		column-gap: 5px;
		font-size: 14px;
		img,
		svg {
			width: 16px;
			min-width: 16px;
		}
	}
}
