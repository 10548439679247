@import '../../../../styles/variables.scss';

.sol-launchpad-detail-summary {
	padding: 30px 0 50px;
	position: relative;
	@media (max-width: 767px) {
		padding: 20px 0 40px;
	}
	&-network-info {
		padding: 8px;
		display: flex;
		flex-direction: row;
		column-gap: 4px;
		align-items: center;
		position: absolute;
		bottom: 42px;
	}

	&-inner {
		display: flex;
		flex-direction: column;
		row-gap: 15px;

		.sol-launchpad-detail-summary-tag {
			display: flex;
			flex-direction: row;
			column-gap: 15px;

			span {
				padding: 8px 16px;
				border-radius: 8px;
				font-weight: 700;
			}

			span:first-child {
				text-transform: capitalize;
				color: var(--sol-color-heading);

				&.sol-launchpad-detail-summary-opening {
					background: var(--sol-color-primary);
				}

				&.sol-launchpad-detail-summary-upcoming {
					background: $warning_color;
				}

				&.sol-launchpad-detail-summary-closed {
					background: $danger_color;
				}
			}

			span:last-child {
				background-color: var(--sol-app-card-bg);
			}
		}
		.sol-page-title{
			margin-bottom: 0;
		}

		.sol-launchpad-detail-summary-desc {
			font-size: 18px;
		}
		.sol-available-on {
			padding-top: 16px;
			border-top: 1px dotted rgba(255, 255, 255, 0.1);
		}
	}
}
