@import 'src/styles/variables.scss';

.sol-bridge-select-network-dialog{
    .sol-networks {
		display: flex;
		flex-direction: column;
		row-gap: 12px;
		&-empty {
			text-align: center;
		}
	}
}
