.sol-public-navbar {
	position: relative;
	@media (max-width: 768px) {
		&::before{
			content: '';
			position: fixed;
			width: 100vw;
			height: 100vh;
			background-color: var(--sol-bg);
			backdrop-filter: blur(6px);
			top: 0;
			left: 100%;
			z-index: 9;
			opacity: .9;
		}
		&.show{
			left: 0;
			&::before{
				left: 0;
			}
			ul{
				left: 0;
			}
		}
	}
	&-toggle {
		display: none;
		width: 52px;
		height: 52px;
		border: 0;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		color: var(--sol-color-heading);
		position: relative;
		z-index: 12;
		@media (max-width: 768px) {
			display: inline-flex;
		}
		svg{
			width: 48px;
			height: auto;
		}
		#top-line,
		#bottom-line,
		#middle-line {
			transform-box: fill-box;
			transform-origin: center;
		}

		&.close {
			#top-line {
				animation: down-rotate 0.6s ease-out both;
			}
			#bottom-line {
				animation: up-rotate 0.6s ease-out both;
			}
			#middle-line {
				animation: hide 0.6s ease-out forwards;
			}
		}

		@keyframes up-rotate {
			0% {
				animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
				transform: translateY(0px);
			}
			30% {
				transform-origin: center;
				animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
				transform: translateY(-12px);
			}
			100% {
				transform-origin: center;
				transform: translateY(-12px) rotate(45deg) scale(0.9);
			}
		}

		@keyframes down-rotate {
			0% {
				animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
				transform: translateY(0px);
			}
			30% {
				transform-origin: center;
				animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
				transform: translateY(12px);
			}
			100% {
				transform-origin: center;
				transform: translateY(12px) rotate(-45deg) scale(0.9);
			}
		}

		@keyframes hide {
			29% {
				opacity: 1;
			}
			30% {
				opacity: 0;
			}
			100% {
				opacity: 0;
			}
		}

	}
	ul {
		display: inline-flex;
		align-items: center;
		column-gap: 52px;
		list-style: none;
		margin: 0;
		padding: 0;
		@media (max-width: 768px) {
			display: flex;
			align-items: center;
			justify-content: center;
			row-gap: 30px;
			flex-direction: column;
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 100%;
			transition: all ease-in-out .3s;
			z-index: 10;
			font-size: 20px;
		}
		li {
			&.active {
				a {
					color: var(--sol-color-primary);
				}
			}
			a {
				font-weight: 600;
				color: var(--sol-color-heading);
				&:hover{
					color: var(--sol-color-primary);
				}
			}
			.btn {
				color: var(--sol-color-primary);
				@media (max-width: 768px) {
					font-size: 20px;
					height: 48px;
				}
				&:hover {
					color: #000;
				}
			}
		}
	}
}
