@import 'src/styles/variables.scss';

.sol-logo {
	display: flex;
	align-items: center;
	height: 52px;
	column-gap: 12px;
	color: var(--sol-color-heading);
	font-size: 18px;
	font-weight: bold;
	text-decoration: none;
	transition: all ease-in-out 0.3s;
	&:hover {
		color: var(--sol-color);
	}
	&-image {
		width: 52px;
		min-width: 52px;
		height: 52px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 1399px) {
			width: 52px;
			min-width: 52px;
			height: 52px;
		}
		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}
	span {
		display: inline-flex;
	}
}
