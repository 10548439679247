@import 'src/styles/variables.scss';

.sol-sidebar-item-wrap {
	position: relative;
	&.active {
		.sol-sidebar-item {
			color: var(--sol-color-primary);
			.sol-sidebar-item-icon {
				color: var(--sol-color-primary);
			}
		}
	}
	.sol-sidebar-item {
		position: relative;
		background-color: transparent;
		border: 0;
		padding: 0;
		display: flex;
		align-items: center;
		column-gap: 16px;
		width: 100%;
		outline: 0;
		color: var(--sol-app-sidebar-color);
		font-size: 14px;
		font-weight: 600;
		transition: all ease-in-out 0.3s;
		&:disabled {
			pointer-events: none;
			opacity: 0.5;
		}
		&:not(:disabled) {
			&:hover {
				color: var(--sol-app-sidebar-hover-color);
				.sol-sidebar-item-icon {
					color: var(--sol-app-sidebar-hover-color);
				}
			}
		}
		&-icon {
			width: 46px;
			min-width: 46px;
			height: 46px;
			border-radius: 46px;
			background-color: #0c0f32;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			transition: all ease-in-out .3s;
			img,
			svg {
				height: 20px;
				width: auto;
			}
		}
	}
}
