.sol-public-logo{
    display: inline-flex;
    align-items: center;
    column-gap: 14px;
    white-space: nowrap;
    img {
    	height: 52px;
    }
    strong {
    	font-weight: 600;
    	font-size: 20px;
    	line-height: 24px;
    	color: var(--sol-color-heading);
    	@media (max-width: 992px) {
    		display: none;
    	}
    }
}
