.sol-public-footer-socials{
    display: flex;
    align-items: center;
    column-gap: 24px;
    flex-wrap: wrap;
    @media (max-width: 992px) {
    	column-gap: 16px;
    }
    a {
    	opacity: 0.7;
    	&:hover {
    		opacity: 1;
    	}
    	img {
    		width: auto;
    		height: 18px;
    		@media (max-width: 992px) {
    			height: 16px;
    		}
    	}
    }
}
