@import 'src/styles/variables.scss';

.sol-sidebar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 998;
	background-color: var(--sol-app-sidebar-bg);
	width: $sidebar_width;
	height: 100vh;
	padding: 22px;
	border-right: 1px #0c0e33 solid;
	@media (max-width: 1399px) {
		width: 220px;
	}
	@media (max-width: 767px) {
		transform: translateX(-100%);
		transition: all ease-in-out 0.3s;
		&.show {
			transform: translateX(0);
		}
	}
	&.show {
		~ .sol-sidebar-overlay {
			max-width: 767px;
			opacity: 1;
			z-index: 997;
		}
	}
	&-menu {
		margin-top: 55px;
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		height: calc(100% - 52px - 55px - 46px - 24px);
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background-color: rgba($color: #fff, $alpha: 0.02);
			border-radius: 3px;
		}

		&::-webkit-scrollbar-thumb {
			background: linear-gradient(
				45deg,
				rgb(0, 63, 221) 0%,
				rgb(24, 255, 113) 100%
			);
			border-radius: 3px;
		}
	}
}

.sol-sidebar-overlay {
	position: fixed;
	top: 0;
	right: 0;
	z-index: -1;
	width: 100vw;
	max-width: 0;
	height: 100vh;
	background-color: rgba($color: $bg_modal_backdrop, $alpha: 0.9);
	opacity: 0;
	text-align: right;
	padding: 30px;
	svg{
		width: auto;
		height: 20px;
		opacity: .8;
	}
	@media(min-width:768px){
		display: none;
	}
}
