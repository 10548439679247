.sol-input-amount {
	width: 100%;
	&-label {
		color: var(--sol-color-heading);
		margin-bottom: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&-sublabel {
		font-size: 14px;
	}
	&-control {
		position: relative;
		&-input {
			width: 100%;
			padding: 10px 15px;
			height: 56px;
			border-radius: 10px;
			background: var(--sol-app-card-bg);
			border: 1px transparent solid;
			transition: all ease-in-out 0.3s;
			color: var(--sol-color-heading);
			font-size: 20px;
			font-weight: 500;
			&:disabled {
				opacity: 0.5;
			}
			&:not(:disabled) {
				&:focus {
					outline: 0;
					border-color: var(--sol-app-input-border);
				}
			}
		}
		.sol-btn-max {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			cursor: pointer;
			display: inline-flex;
			padding: 10px;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			border: 1px solid var(--sol-app-input-border);
			color: var(--sol-color-heading);
			font-size: 13px;
            line-height: 1;
			transition: all ease-in-out 0.3s;
            background-color: transparent;
            text-transform: uppercase;
			&:hover {
				background-color: var(--sol-color-primary);
			}
		}
	}
	&-note {
		font-size: 14px;
		padding-top: 8px;
		@media(max-width:767px){
			font-size: 12px;
			line-height: 1.5;
		}
	}

	&-reverse {
		display: flex;
		gap: 10px;
		flex-direction: column-reverse;

		.sol-input-amount-label {
			display: flex;
			justify-content: flex-end;
		}
	}
}
